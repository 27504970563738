<template>
	<div class="realtime">
		<div class="realtime-T">
			<div class="realtime-TL">
				<Breadcrumb class="bre"></Breadcrumb>
				<div class="realtime-searchs">
					<inputs @inputs="inputserValue" @selects="selectsValue" :inputShow="true"
						:selectShow='true' inputPlace0="请输入名称" inputPlace="请输入SN" :options="options"></inputs>
					<div class="realtime-tit-mai1-btn">
						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div>
			</div>
			<div class="realtime-TR">
				<div class="realtime-searchsMai">
				</div>
			</div>
		</div>
		<div class="realtime-B" @mousemove="GetMou">
			<div class="realtime-B1">
				<i class="el-icon-alarm-clock" style="font-size: 26px;"></i>
				<div style="width:160px;margin:0 10px;font-family: georgia;">{{TimeShow}}</div>
				<div style="font-size: 12px;margin-left: 5px;">实时更新中...</div>
				<div><i class="el-icon-loading"></i></div>
			</div>
			<div class="realtime-B2">
				<div v-if="tableData==null" style="color: #7a7a7a;"><el-empty description="暂无其他数据"></el-empty></div>
				<div v-else class="realtime-B22">
					<div class="realtime-B-list" v-for="(item,index) in tableData" :key="index">
						<div :class="item.T_monitor==1 && item.T_link == 1?'realtime-B-list-t':'realtime-B-list-off'">
							<div class="realtime-B-list-tL">
								<div class="realtime-B-list-tName" :title="item.T_name">
									名称：{{item.T_name}}
								</div>
								<div class="realtime-B-list-tSn">
									Sn：{{item.T_sn}}
								</div>
							</div>
							<div class="realtime-B-list-tR" @click="showMon(item)">
								<!-- <div v-if="item.T_DeviceSensorParameter.T_free==1" style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
									<img src="@/assets/img/no_store.svg" style="width: 25px;height:25px;">
									<div style="font-size: 12px;transform: scale(.8);">空库</div>
								</div> -->
								<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
									<img v-if="item.T_monitor==1 && item.T_link == 1" src="@/assets/img/jk.png" style="width: 25px;height:25px;">
									<img v-else src="@/assets/img/wjk.png" style="width: 25px;height:25px;">

									<!-- <img v-else src="@/assets/img/no_store.svg" style="width: 25px;height:25px;"> -->
								</div>
								<div style="font-size: 12px;transform: scale(.8);">
									{{item.T_monitor==1 && item.T_link == 1?'监控中':'未监控'}}
								</div>
							</div>
						</div>
						<div class="realtime-B-list-c">
							<div class="realtime-B-list-cL">
								<div class="realtime-B-list-cL-t" v-if="item.T_DeviceSensorData.T_ist==1">
									<div class="realtime-B-list-cL-tL">
										<div class="realtime-B-list-cL-tL-img">
											<img src="@/assets/img/t.png" style="width: 100%;height: 100%;">
										</div>
										<div class="realtime-B-list-cL-tL-txt">
											温度
										</div>
									</div>
									<div class="realtime-B-list-cL-tR">
										<div :class="
                                            item.T_DeviceSensorParameter.T_free!=1?
                                                item.T_monitor==1 && item.T_link == 1 &&item.T_online===1 || item.T_online_s===1?
                                                item.T_DeviceSensorData.T_tl<=item.T_DeviceSensorData.T_t && item.T_DeviceSensorData.T_tu>=item.T_DeviceSensorData.T_t?
                                                'realtime-B-list-cL-tR1':'realtime-B-list-cL-tRoff'
                                                :'realtime-B-list-cL-tRhui'
                                            :'realtime-B-list-cL-tRhui'">
											{{item.T_monitor==1 && item.T_link == 1?item.T_DeviceSensorData.T_t:'--'}}
										</div>
										<div class="realtime-B-list-cL-tR2">
											℃
										</div>
									</div>
								</div>
								<div class="realtime-B-list-cL-t" v-if="item.T_DeviceSensorData.T_ish==1" style="margin-top: 10px;">
									<div class="realtime-B-list-cL-tL">
										<div class="realtime-B-list-cL-tL-img">
											<img src="@/assets/img/h.png" style="width: 90%;height: 90%;">
										</div>
										<div class="realtime-B-list-cL-tL-txt">
											湿度
										</div>
									</div>
									<div class="realtime-B-list-cL-tR">
										<div :class="
                                            item.T_DeviceSensorParameter.T_free!=1?
                                                item.T_monitor==1 && item.T_link == 1 &&item.T_online===1 || item.T_online_s===1?
                                                item.T_DeviceSensorData.T_rhl<=item.T_DeviceSensorData.T_rh && item.T_DeviceSensorData.T_rhu>=item.T_DeviceSensorData.T_rh?
                                                'realtime-B-list-cL-tR1':'realtime-B-list-cL-tRoff'
                                                :'realtime-B-list-cL-tRhui'
                                            :'realtime-B-list-cL-tRhui'">
											{{item.T_monitor==1 && item.T_link == 1?item.T_DeviceSensorData.T_rh:'--'}}
										</div>
										<div class="realtime-B-list-cL-tR2">
											%
										</div>
									</div>
								</div>
							</div>
							<div class="realtime-B-list-cR">
								<div class="realtime-B-list-cRt" @dblclick="showDia(item)">
									<div class="realtime-B-list-cRtl">
										<div class="realtime-B-list-cRtl-t">
											{{item.T_DeviceSensorData.T_tl}}~{{item.T_DeviceSensorData.T_tu}}
										</div>
										<div class="realtime-B-list-cRtl-b">
											温度阈值
										</div>
									</div>
									<div class="realtime-B-list-cRtl">
										<div class="realtime-B-list-cRtl-t">
											{{item.T_DeviceSensorData.T_rhl}}~{{item.T_DeviceSensorData.T_rhu}}
										</div>
										<div class="realtime-B-list-cRtl-b">
											湿度阈值
										</div>
									</div>
								</div>
								<div class="realtime-B-list-cR">
									<div class="realtime-B-list-cRL">
										<div class="realtime-B-list-cRL-img">
											<img v-if="item.T_link==1" src="@/assets/img/lianJ.svg" style="width: 100%;height: 100%;">
											<img v-if="item.T_link==0" src="@/assets/img/lianJ_yc.svg" style="width: 80%;height: 80%;">
										</div>
										<div class="realtime-B-list-cRL-txt">
											{{item.T_link===1?'连接':'断开'}}
										</div>
									</div>
									<div class="realtime-B-list-cRR">
										<div class="realtime-B-list-cRL-dc">
											<img v-if="item.T_DeviceSensorParameter.T_free==0" src="@/assets/img/zhengchang.svg" style="width: 80%;height: 80%;">
											<div v-if="item.T_DeviceSensorParameter.T_free==1" 
											style="width: 80%;height: 80%;background: #e6a23c;color: #fff;
											display: flex;align-items: center;justify-content: center;border-radius: 2px;">空</div>
										</div>
										<div class="realtime-B-list-cRL-txt">
											{{item.T_DeviceSensorParameter.T_free==0?'启用':item.T_DeviceSensorParameter.T_free==1?'空库':'未知'}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="realtime-B-list-b">
							<div class="realtime-B-list-bL">
								{{item.T_DeviceSensorData.T_time=='0001-01-01 00:00:00'?'暂无数据':item.T_DeviceSensorData.T_time}}
							</div>
							<div class="realtime-B-list-bR">
								<div class="realtime-B-list-cRL">
									<div class="realtime-B-list-cRL-img">
										<img v-if="item.T_online===1 || item.T_online_s===1" src="@/assets/img/wlzx.png" style="width: 80%;height: 80%;">
										<img v-else src="@/assets/img/wllx.png" style="width: 80%;height: 80%;">
									</div>
									<div class="realtime-B-list-cRL-txt">
										{{item.T_online===1 || item.T_online_s===1?'在线':'离线'}}
									</div>
								</div>
								<div class="realtime-B-list-cRR">
									<div class="realtime-B-list-cRL-dc">
										<div class="realtime-B-list-cRL-dc1">
											{{item.T_Dattery==0?'x':item.T_Dattery}}
										</div>
										<div class="realtime-B-list-cRL-dc2"></div>
									</div>
									<div class="realtime-B-list-cRL-txt">
										电量
									</div>
								</div>
								<!-- <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
									<div style="height: 20px;line-height: 20px;">No.{{item.T_id}}</div>
									<div style="font-size: 10px; transform: scale(0.8); color: #7a7a7a;height: 20px;">版本号</div>
								</div> -->
							</div>
						</div>
					</div>
				</div>
				<div class="realtime-page">
					 <!-- :hide-on-single-page="hideSin" 低于一页掩藏分页 -->
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
						:current-page="pages.page" :page-size="pages.page_z"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog title="设备设置" :visible.sync="dialogVisible4" :append-to-body="true">
			<el-tabs v-model="activeName1" @tab-click="handleClick">
				<el-tab-pane label="传感器参数" name="firsts">
					<el-form ref="form1" :model="diaForm1" label-width="100px" style="height: 500px;overflow-y: auto;">
						<el-form-item label="传感器名称">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_name" placeholder="请输入传感器名称" style="width: 70%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="设备名称在0-20个字节">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="传感器采样率">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_speed" placeholder="请输入传感器采样率" style="width: 70%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="秒(1~240)">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<br />
						<el-form-item label="温度范围">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_Tlower" placeholder="请输入温度范围下限" style="width: 20%;"></el-input>
								<span style="margin: 0 10px;">-</span>
								<el-input v-model="diaForm1.T_Tupper" placeholder="请输入温度范围上限" style="width: 20%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="-40.0 ~ 80.0">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="湿度范围">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_RHlower" placeholder="请输入湿度范围下限" style="width: 20%;"></el-input>
								<span style="margin: 0 10px;">-</span>
								<el-input v-model="diaForm1.T_RHupper" placeholder="请输入湿度范围上限" style="width: 20%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="-40.0 ~ 80.0">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
		
						<el-form-item label="预警">
							<div style="display: flex;align-items: center;">
								<el-switch v-model="diaForm1.T_enprel" :inactive-value="0" :active-value="1"></el-switch>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="推送预警消息，设为 空库后 不推送预警消息">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="温度范围">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_tprel" placeholder="请输入温度范围下限" style="width: 20%;"></el-input>
								<span style="margin: 0 10px;">-</span>
								<el-input v-model="diaForm1.T_tpreu" placeholder="请输入温度范围上限" style="width: 20%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="0.0 ~ 100.0">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="湿度范围">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm1.T_hprel" placeholder="请输入湿度范围下限" style="width: 20%;"></el-input>
								<span style="margin: 0 10px;">-</span>
								<el-input v-model="diaForm1.T_hpreu" placeholder="请输入湿度范围上限" style="width: 20%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="0.0 ~ 100.0">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="启用">
							<div style="display: flex;align-items: center;">
								<el-switch v-model="diaForm1.T_en" :inactive-value="0" :active-value="1"></el-switch>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="功能（实时、记录、报警、预警）">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="空库">
							<div style="display: flex;align-items: center;">
								<el-switch v-model="diaForm1.T_free" :inactive-value="0" :active-value="1"></el-switch>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="1.启用（正常 实时、记录, 不 报警、预警）2.关闭（正常 实时、记录、报警、预警）">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
					</el-form>
					<div class="diapir-nav1-2">
						<div style="display: flex;justify-content: space-between;width: 100%;">
							<div>
								<el-button type="primary" @click="onSubpast1">设备参数记录</el-button>
							</div>
							<div>
								<el-button @click="dialogVisible = false">取消</el-button>
								<el-button type="primary" @click="Submit1">立即提交</el-button>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="传感器配置" name="seconds">
					<el-form ref="form2" :model="diaForm2" label-width="100px">
						<el-form-item label="实时数据排序">
							<div style="display: flex;align-items: center;">
								<el-input v-model="diaForm2.T_sort" style="width: 70%;"></el-input>
								<el-popover placement="top-start" title="提示" width="200" trigger="hover"
									content="越小越靠前,可以为负数">
									<i class="el-icon-question" slot="reference"
										style="margin-left:10px;cursor: pointer;"></i>
								</el-popover>
							</div>
						</el-form-item>
						<el-form-item label="3D视图订阅">
							<el-input v-model="diaForm2.T_3dview" style="width: 70%;"></el-input>
						</el-form-item>
						<el-form-item label="类型选择">
							<el-radio-group v-model="diaForm2.T_type">
								<el-radio :label="0">默认</el-radio>
								<el-radio :label="1">物流端</el-radio>
								<el-radio :label="2">运输端</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="屏蔽数据展示">
							<el-radio-group v-model="diaForm2.T_datashow">
								<el-radio :label="0">屏蔽展示</el-radio>
								<el-radio :label="1">正常展示</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-form>
					<div class="diapir-nav1-2">
						<el-button @click="dialogVisible = false">取消</el-button>
						<el-button type="primary" @click="Submit2">立即提交</el-button>
					</div>
				</el-tab-pane>
			</el-tabs>
			<el-dialog width="700px" title="设备参数记录" :visible.sync="dialogVisible4inner" append-to-body>
				<div style="display: flex;align-items: center;padding-bottom: 10px;">
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #909399;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">等待执行</p>
					</div>
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #67c23a;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">修改成功</p>
					</div>
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #f56c6c;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">修改失败</p>
					</div>
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<span
							style="display: block;width: 15px;height: 15px;background: #e6a23c;border-radius: 50%;"></span>
						<p style="font-size: 12px;margin-left: 5px;">覆盖参数</p>
					</div>
				</div>
				<div style="height: 500px;overflow-y: auto;padding-right: 20px;">
					<div v-if="sensorList==null">
						<noData></noData>
					</div>
					<el-alert :title="item.T_text" style="margin-top: 10px ;" :closable="false"
						:type="item.T_SendState==0?'info':item.T_SendState==1?'success':item.T_SendState==2?'error':'warning'"
						show-icon v-for="item,index in sensorList" :key="index">
					</el-alert>
				</div>
			</el-dialog>
		</el-dialog>
		<el-dialog
		  title="远程启停验证"
		  :visible.sync="centerDialogVisible"
		  width="30%"
		   :append-to-body="true"
		   @closed='closeds'
		  center>
		  <ifs @changeCode="changeCode" ref="child"></ifs>
		  <el-input v-model="codeVal" placeholder="请输入上图验证码"></el-input>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="centerDialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="subCode">确 定</el-button>
		  </span>
		</el-dialog>
		
	</div>
</template>

<script>
	import ifs from '@/components/identify.vue'
	import {
		ClassList,
		DevSenList
	} from '../../api/dataDisplay/realtime.js'
	import {
		DevParGet,
		DevParParPu,
		DevSenEdit,
		DevTask,
		DevSenParList 
	} from "../../api/equipmentManagement/management.js"
	import {formatDate} from '../../utils/Times.js'
	import {
		mapGetters
	} from "vuex"
	export default {
		computed: mapGetters(["showModel"]),
		components: {ifs},
		data() {
			return {
				Setmodel:null,//鼠标滑动显示蒙版
				modexTime:0,//鼠标开始计时
				modeltimes:null,
				sensorList:[],
				idCode:null,//传过来的验证码
				codeVal:null,//输入框的验证码
				desData:null,
				centerDialogVisible:false,
				dialogVisible4:false,
				dialogVisible4inner:false,
				activeName1:'firsts',
				diaForm1:{},
				diaForm2:{},
				tableData: [],
				hideSin:true,
				pages: {
					T_RealTime:1,
					T_name: '',
					T_calssid: '',
					page: 1,
					page_z:10,
				},
				pages2: {
					T_id: '',
					T_sn: '', //设备SN
				},
				total: 0,
				options: [],
				times:null,

				TimeShow:'',
				setTimeouts:null
			}
		},
		created() {
			this.TimeShow = formatDate(new Date())
		},
		mounted() {
			this.GetClassListApi()
			this.GetDevSenListApi()
			this.CurrentTime()
			this.setModelTime()
		},
		watch:{
			showModel:{
				handler(val){
					////console.log('监听',val)
					if(!val){//为false重新开始定时
						clearInterval(this.modeltimes)//清除定时器
						this.modeltimes = null
						this.setModelTime()
						this.GetDevSenListApi()//重新开始时调用列表数据
					}else{
						clearTimeout(this.setTimeouts)
					}
				}
			}
		},
		beforeDestroy() {
			clearInterval(this.times)
			clearInterval(this.modeltimes)//清除蒙版的定时器
			clearTimeout(this.setTimeouts)
			// showModel = true
			// this.$store.dispatch('SETMODEL',false)
			////console.log('离开1')
		},
		// destroyed(){
		// 	////console.log('离开2')
		// },
		methods:{
			GetMou(e){
				
				this.$nextTick(()=>{
					this.modexTime = 0
					this.$store.dispatch('SETMODEL',false)
					clearInterval(this.modeltimes)//清除蒙版的定时器
					this.setModelTime()
				})
			},
			setModelTime(){
				let that = this
				this.modeltimes = setInterval(() => {
					that.modexTime += 1
					////console.log('定时',that.modexTime)
					if(that.modexTime==120){//120秒  
						clearInterval(this.modeltimes)//清除蒙版的定时器
						that.modeltimes = null
						that.modexTime = 0
						that.Setmodel = false
						that.$store.dispatch('SETMODEL',true)
					}else{//离开界面清除
						that.$store.dispatch('SETMODEL',false)
					}
					// ////console.log('定时',that.modexTime)
				}, 1000);
			},
			changeCode(e){//验证码
				this.idCode = e
			},
			closeds(){
				this.$refs.child.handleClick()
			},
			showMon(item){
				if(item.T_monitor === 2){
					this.$message.error('网络不佳')
					return
				}else{
					this.centerDialogVisible = true
					this.desData = item
				}
			},
			subCode(){
				if(this.codeVal==null){
					this.$message.error('请输入验证码')
					return
				}
				if(this.codeVal===this.idCode){
					this.DevTask()
				}else{
					this.$message.error('你输入的验证码不匹配,请重新输入')
				}
			},
			DevTask() { //远程启停
				var that = this
				////console.log('远程启停',this.desData)
				if (this.desData.T_online == 1 || this.desData.T_online_s==1) {
					DevTask({
						T_sn: this.desData.T_sn,
						T_task: this.desData.T_monitor === 1 ? 'stop' : 'start'
					}).then(res => {
						if (res.data.Code === 200 && res.data.Msg === "ok!") {
							that.$message.success('远程启停，设置成功')
							this.centerDialogVisible = false
						}
					})
				} else {
					this.$message.error('设备离线')
				}
			},
			showDia(item){
				////console.log('item',item)
				this.diaForm2 = {
					T_id:item.T_id,
					T_sn:item.T_sn,
					T_sort:item.T_sort,
					T_type:item.T_type,
					T_datashow:item.T_datashow,
					T_3dview:item.T_3dview,
				}
				this.pages2.T_id = item.T_id
				this.pages2.T_sn = item.T_sn
				this.activeName1 = 'firsts',
				this.getPaGetApi(item.T_name)
			},
			getPaGetApi(item){//传感器参数获取数据
				DevParGet(this.pages2).then(res=>{
					////console.log(res)
					if(res.data.Code==200){
						this.dialogVisible4=true
						this.diaForm1 = res.data.Data[0]
					}
				})
			},
			
			Submit1(){
				DevParParPu(this.diaForm1).then(res=>{
					////console.log('提交1',res)
					if(res.data.Code==200){
						this.dialogVisible4=false
						this.$message.success('操作成功，传感器参数设置成功')
						// this.GetSenListApi()
					}
				})
			},
			Submit2(){
				DevSenEdit(this.diaForm2).then(res=>{
					if(res.data.Code==200){
						this.dialogVisible4=false
						this.$message.success('操作成功，传感器配置成功')
						// this.GetSenListApi()
					}
				})
			},
			onSubpast1() { //
				////console.log('****',this.pages2)
				DevSenParList(this.pages2).then(res => {
					////console.log('历史数据15151',res)
					if (res.data.Code === 200) {
						this.dialogVisible4inner = true
						this.sensorList = res.data.Data
					}
				})
			},
			handleClick(tab, event) {
				////console.log('sss', tab, event);
			},
			
			
			
			CurrentTime(){
				this.times = setInterval(()=>{
					this.TimeShow = formatDate(new Date())
				},1000)
			},
			//左侧导航菜单
			async GetDevSenListApi() {
				const that = this
				var resIt = await this.GetDevSenPam()
				if(resIt.data.Code === 200){
					this.hideSin = true
					this.total = resIt.data.Data.Num
					this.tableData = resIt.data.Data.DeviceSensor_lite
					if(that.showModel==false && this.$route.name=='realtime'){
						// ////console.log('查看',this.$route.name=='realtime')
						that.setTimeouts = setTimeout(() => {
							that.GetDevSenListApi()
						},1000);
					}else{
						// that.$store.dispatch('SETMODEL',false)
						clearTimeout(that.setTimeouts)
					}
					
					// ////console.log('列表88888',resIt)
				}
			},
			//左侧导航菜单
			async GetSenListApi() {
				const that = this
				var resIt = await this.GetDevSenPam()
				if(resIt.data.Code === 200){
					this.hideSin = true
					this.total = resIt.data.Data.Num
					this.tableData = resIt.data.Data.DeviceSensor_lite
				}
			},
			GetDevSenPam() {
				return new Promise(resolve => {
					DevSenList(this.pages).then(function(res) {
						resolve(res);
					})
				});
			},
			GetClassListApi(){
				var that = this
				ClassList({}).then(res=>{
					if (res.data.Code === 200 && res.data.Msg === 'ok!') {
						var Arr = res.data.Data.Data
						// ////console.log('ppppppppppp',Arr)
						if(Arr!=null){
							Arr.forEach(function(item) {
								var obj = {
									value: '',
									label: ''
								}
								obj.value = item.Id
								obj.label = item.T_name
								that.options.push(obj)
							})
							that.options.unshift({
								value: '',
								label: '所有分类'
							})
						}
					}
				})
			},
			inputserValue(e){//输入sn
				////console.log('ww',e)
				this.pages.T_name = e
				this.pages.page = 1
				this.GetSenListApi()
			},
			selectsValue(e){//select
				console.log('ss',e)
				this.pages.T_calssid = e
				this.pages.page = 1
				this.GetSenListApi()
			},
			handleSizeChange(e) { //每页多少条
				////console.log(e)
				this.pages.page_z = e
				this.GetSenListApi()
			},
			handleCurrentChange(e) { //跳转页
				////console.log('下一页')
				this.pages.page = e
				this.GetSenListApi()
			}
		}
	}
</script>

<style lang="scss">
	@import '../../assets/css/dataDisplay/realtime.scss';
</style>
